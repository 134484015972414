import { router } from "../routes";

export default {
  data() {
    return {
      pagination: {
        perPage: 10,
        currentPage: parseInt(this.$route.query.page) || 1,
        total: 0,
      },
      searchQuery: '',
      sortBy: '',
      sortOrder: '',
      currentPageManuallyChanged: false,
    };
  },
  computed: {
    currentPage() {
      return this.pagination.currentPage;
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.queriedData.length;
    },
  },
  mounted() {
    let currPage = this.$route.query.page;
    if (currPage && parseInt(currPage) !== this.pagination.currentPage) {
      this.pagination.currentPage = parseInt(currPage);
      this.currentPageManuallyChanged = true;
    }
  },
  methods: {
    sortChange({ prop, order }) {
      this.sortBy = prop;
      this.sortOrder = order;
      this.pagination.currentPage = 1;
    },
  },
  watch: {
    currentPage() {
      if (!this.currentPageManuallyChanged) {
        this.currentPageManuallyChanged = false;
        router.replace({query: {
          page: this.currentPage,
        }});
      }
    },
  },
};
